// import { Modal } from "flowbite";
import { getRequest } from "../util/util.js";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

// console.log("##ComparePage",location)

// api urls
const ALLBoatsURL = `${location.origin}/umbraco/api/boats/GetAllBoatsWithoutTrimPackages`;
const ALLSeriesURL = `${location.origin}/umbraco/api/boats/GetAllSeries`;
const boatByIdURL = (id) =>
    `${location.origin}/umbraco/api/boats/GetBoatById/${id}`;

const boatSelectElems = document.querySelectorAll(
    ".js--compare-boat__selection > [data-boat-id][data-boat-series]"
);
// console.log("#boatselet", boatSelectElems);
const boatFamilySelect = document.getElementById("select-boat-family");
const boatModelSelect = document.getElementById("select-boat-model");
const submitBtn = document.getElementById("boat-select-submitBtn");
const compare = document.getElementById("compare-difference");
const compTab = document.getElementById("compare-tab");

export function showModal(e) {
    const modal = document.getElementById("boat-select-modal");
    modal.showModal();
    // console.log(e.currentTarget);
    currSelection = e.currentTarget.dataset.index;
}

export function showToolTip(e) {
    e.preventDefault();
    const toolTip = e.currentTarget.parentElement.querySelector("dialog");
    toolTip.showModal();
    const closeBtn = toolTip.querySelector("span.absolute");
    toolTip.scroll({top:0})
    const iframe = toolTip.querySelector("iframe");
    if (iframe != null) {
        var src = iframe.src;
        let autoplaysrc = src;
        if (!autoplaysrc.includes("autoplay=1")) {
            autoplaysrc += (autoplaysrc.includes("?") ? "&" : "?") + "autoplay=1";
        }
        iframe.src = autoplaysrc;
    }
    closeBtn.addEventListener("click", () => {
        toolTip.close();
        if (iframe != null) {
            iframe.setAttribute("src", "");
            iframe.setAttribute("src", src);
        }
    }, { once: true }
    );
}

let currSelection = 0;
let selectedBoats = [];
let selectedSeries = [];
let selectedBoxes = [];

async function selectBoat() {
    const allBoats = await getRequest(ALLBoatsURL);
    const allSeries = await getRequest(ALLSeriesURL);

    // console.log("##Allboats and series",allBoats,allSeries)
    boatSelectElems.forEach((item, i) => {
        // console.log("#CCC inside",i ,item,)
        item.addEventListener("click", showModal);
    });

    // Poplate options for first time
    boatFamilySelect.options.length = 0;
    allSeries.forEach(
        (item, i) =>
            (boatFamilySelect.options[i] = new Option(item.name, item.series))
    );
    boatModelSelect.options.length = 0;
    filterBoatBySeries(boatFamilySelect.value, allBoats).forEach((boat) => {
        boatModelSelect.options[boatModelSelect.options.length] = new Option(
            boat.name,
            boat.id
        );
    });
    if (compTab != null) {
        compTab.addEventListener("click", compTabSelect, { once: true });
        compTab.addEventListener("click", stickyNav, { once: true });
        // ScrollTrigger.create({
        //   trigger: ".js--compare-boat",
        //   //  markers:true,
        //   start: "center 48px",
        //   end: () => `+=${document.querySelector("#compare-skeleton").offsetHeight}`,
        //   toggleClass: "stickyBox",
        //   onEnter: (self) =>
        //     self.trigger.nextElementSibling.style.setProperty(
        //       "--sticky-box-height",
        //       `${height}px`
        //     ),
        // });
    }

    async function compTabSelect(e) {
        // console.log("Inside compTabselect")
        e.preventDefault();
        const { boatid, boatseries } = e.currentTarget.dataset;
        let boatId = parseInt(boatid);
        const boat = await getRequest(boatByIdURL(boatId));
        const boatfeatures = boatfeature(boat);
        // console.log("## Onclick compare tab",boatId,boatseries);
        let curr = document.getElementById(`boat-select-0`);
        curr.dataset.boatId = boatId;
        curr.dataset.boatSeries = boatseries;
        curr.removeEventListener("click", showModal);
        getCurrentSelections();

        let filteredSeries = filterCollection(
            selectedSeries,
            allSeries,
            "series",
            false
        );
        boatFamilySelect.options.length = 0;
        filteredSeries.forEach(
            (item, i) =>
                (boatFamilySelect.options[i] = new Option(item.name, item.series))
        );
        // console.log("filteredSeries", filteredSeries);
        boatModelSelect.options.length = 0;
        filterCollection(
            selectedBoats,
            filterBoatBySeries(boatFamilySelect.value, allBoats),
            "id",
            false
        ).forEach(
            (item, i) => (boatModelSelect.options[i] = new Option(item.name, item.id))
        );
        const accSkeleton = document.getElementById("compare-accordion");
        accSkeleton
            .querySelectorAll(`.js--compare-value > [data-index="0"]`)
            .forEach((item) => {
                // console.log("#CC before emptying boxes",{item},item.innerText);
                item.innerHTML = "-";
                // console.log("#CC after emptying boxes",{item},item.innerText);
            });
        updateValue(boatfeatures);
        if (compare.checked) {
            comparedifference();
        }
    }
    submitBtn.addEventListener("click", handleSubmit);

    async function updateBoat(boatId, boatSeries) {
        const boat = await getRequest(boatByIdURL(boatId));
        const boatfeatures = boatfeature(boat);
        //console.log("Select Btn",boat,boatfeatures);
        const boatHtml = await selectBoxBoatHtml(boat);
        // console.log("##69",boat,boatfeatures);
        let curr = document.getElementById(`boat-select-${currSelection}`);
        curr.dataset.boatId = boatId;
        curr.dataset.boatSeries = boatSeries;
        curr.removeEventListener("click", showModal);
        curr.firstElementChild.ariaHidden = true;
        curr.lastElementChild.remove();
        curr.insertAdjacentHTML("beforeend", boatHtml);
        getCurrentSelections();

        let filteredSeries = filterCollection(
            selectedSeries,
            allSeries,
            "series",
            false
        );
        boatFamilySelect.options.length = 0;
        filteredSeries.forEach(
            (item, i) =>
                (boatFamilySelect.options[i] = new Option(item.name, item.series))
        );
        // console.log("filteredSeries", filteredSeries);
        boatModelSelect.options.length = 0;
        filterCollection(
            selectedBoats,
            filterBoatBySeries(boatFamilySelect.value, allBoats),
            "id",
            false
        ).forEach(
            (item, i) => (boatModelSelect.options[i] = new Option(item.name, item.id))
        );
        const accSkeleton = document.getElementById("compare-accordion");
        accSkeleton
            .querySelectorAll(`.js--compare-value > [data-index="${currSelection}"]`)
            .forEach((item) => {
                // console.log("#CC before emptying boxes",{item},item.innerText);
                item.innerHTML = "-";
                // console.log("#CC after emptying boxes",{item},item.innerText);
            });
        updateValue(boatfeatures);
        if (compare.checked) {
            comparedifference();
        }
    }
    async function handleSubmit(e) {
        e.preventDefault();
        const modal = document.getElementById("boat-select-modal");
        if (boatModelSelect.options.length == 0) {
            alert("Select some other boat");
        }
        let boatId = boatModelSelect.value;
        let boatSeries = boatFamilySelect.value;

        updateBoat(boatId, boatSeries);
        modal.close();
        // const boat = await getRequest(boatByIdURL(boatId));
        // const boatfeatures = boatfeature(boat);
        // console.log("Select Btn",boat,boatfeatures);
        // const boatHtml = await selectBoxBoatHtml(boat);
        // console.log("##69",boat,boatfeatures);
        // let curr = document.getElementById(`boat-select-${currSelection}`);
        // curr.dataset.boatId = boatId;
        // curr.dataset.boatSeries = boatSeries;
        // curr.removeEventListener("click", showModal);
        // curr.firstElementChild.ariaHidden = true;
        // curr.lastElementChild.remove();
        // curr.insertAdjacentHTML("beforeend", boatHtml);
        // getCurrentSelections();
        // modal.close();
        // let filteredSeries = filterCollection(
        //   selectedSeries,
        //   allSeries,
        //   "series",
        //   false
        // );
        // boatFamilySelect.options.length = 0;
        // filteredSeries.forEach(
        //   (item, i) =>
        //     (boatFamilySelect.options[i] = new Option(item.name, item.series))
        // );
        // // console.log("filteredSeries", filteredSeries);
        // boatModelSelect.options.length = 0;
        // filterCollection(
        //   selectedBoats,
        //   filterBoatBySeries(boatFamilySelect.value, allBoats),
        //   "id",
        //   false
        // ).forEach(
        //   (item, i) => (boatModelSelect.options[i] = new Option(item.name, item.id))
        // );
        // const accSkeleton = document.getElementById("compare-accordion");
        // accSkeleton
        //   .querySelectorAll(`.js--compare-value > [data-index="${currSelection}"]`)
        //   .forEach((item) => {
        //     console.log("#CC before emptying boxes",{item},item.innerText);
        //     item.innerHTML = "-";
        //     console.log("#CC after emptying boxes",{item},item.innerText);
        //   });
        // updateValue(boatfeatures);
        // if (compare.checked){
        //   comparedifference()
        // }
    }

    compare.addEventListener("click", () => {
        // console.log({compare},compare.checked, typeof compare.checked);
        if (compare.checked) {
            comparedifference();
        } else {
            const fw = document.querySelectorAll(".js--feature-wrapper");
            fw.forEach((item) => {
                item.ariaHidden = false;
            });
        }
    });
    submitBtn.addEventListener("click", stickyNav, { once: true });

    boatFamilySelect.addEventListener("change", function changeHandler(e) {
        boatModelSelect.options.length = 0;
        filterCollection(
            selectedBoats,
            filterBoatBySeries(boatFamilySelect.value, allBoats),
            "id",
            false
        ).forEach(
            (item, i) => (boatModelSelect.options[i] = new Option(item.name, item.id))
        );
    });
}
// console.log("## boatselectlength",boatSelectElems.length, Boolean(boatSelectElems.length))
if (boatSelectElems.length) {
    // console.log("##Indside if bef selectboat()")
    selectBoat();
    // console.log("##Indside if after selectboat()")
}

function stickyNav() {
    document.getElementById("compare-skeleton").ariaHidden = false;
    // const height = document.querySelector(".js--compare-boat").offsetHeight;
    const stickyNav = document.querySelector("#sticky-nav-container");
    const masthead = document.querySelector("#masthead");
    // Sticky box bar
    ScrollTrigger.create({
        trigger: ".js--compare-boat",
        //  markers:true,
        start: "center 48px",
        end: () => `+=${document.querySelector("#compare-skeleton").offsetHeight}`,
        toggleClass: "stickyBox",
        onEnter: (self) => {
            // self.trigger.nextElementSibling.style.setProperty(
            //   "--sticky-box-height",
            //   `${height}px`
            // );
            if (stickyNav != null) {
                // console.log("#CC 312 stickyNav height",stickyNav.offsetHeight,masthead.offsetHeight, stickyNav.offsetHeight+masthead.offsetHeight)
                self.trigger.style.setProperty(
                    "--sticky-masthead-height",
                    `${stickyNav.offsetHeight}px`
                );
                self.trigger.style.setProperty(
                    "--masthead-height-combined",
                    `${stickyNav.offsetHeight + masthead.offsetHeight}px`
                );
            }
        },
    });

    removeAspectSquare();
}

// async function handleBoatSelection(e) {
//   let modalId = boatModelSelect.value;

// }
/**
 *
 * @param {featureObject} features
 */
function updateValue(features) {
    // List of features for which to display description
    const opt = ["design", "dimensions", "capacity"];
    for (var key in features) {
        const isDescription = opt.includes(key);
        features[key].forEach((item) => {
            const { featureName, featureDescription, featureUpgrage } = item;
            // console.log(key,featureName,featureDescription,featureUpgrage);
            const fnId = featureName
                .trim()
                .replace(/[\s~`!@#$%^&*(){}\[\];:"'<,.>?\/\\|_+=-]/g, "")
                .toLowerCase(); // i => Boat & Height = boatheight
            // console.log(fnId);
            if (isDescription) {
                document.querySelector(
                    `#_${fnId} .js--compare-value > [data-index="${currSelection}"]`
                ).innerText = featureDescription;
            } else {
                document.querySelector(
                    `#_${fnId} .js--compare-value > [data-index="${currSelection}"]`
                ).innerText = featureUpgrage;
            }
        });
    }
}

/**
 *
 * @param {string} series -Name of series
 * @param {boats[]} boats - Array of boats
 * @returns boats[] || [] Array of boats filtered by series
 */

function filterBoatBySeries(series, boats) {
    return boats.filter(
        (item) => item.series.trim().toLowerCase() == series.toLowerCase()
    );
}

/**
 *
 * @param {prop[]} id - Array of typeof(attr) ie strung[] or number[]
 * @param {collection[]} arr - Array of objects to filter from ex. allboats, allseries
 * @param {string} prop - basis of filter propertyname in collection eg. "id" or "series"
 * @param {boolean} flag
 * @returns - if @param flag is true return filtred Collection equal to id[],
 *          - else return filtered collection not equal to id[]
 */
function filterCollection(id = [], arr, prop, flag = false) {
    // console.log(id, arr, prop);
    if (flag) {
        return arr.filter((item) => id.includes(item[prop]));
    }
    return arr.filter((item) => !id.includes(item[prop]));
}

function getCurrentSelections() {
    let ser = [];
    let box = [];
    boatSelectElems.forEach((elem, i) => {
        const { boatId, boatSeries } = elem.dataset;
        const isSelected = elem.firstElementChild.ariaHidden == "true";
        // console.log("##aria hidden",i,isSelected);
        // console.log(isSelected,typeof isSelected, typeof elem.firstElementChild.ariaHidden);
        if (isSelected) {
            box[i] = i;
        }
        if (!!boatId) {
            selectedBoats[i] = parseInt(boatId);
        }
        if (
            !!boatSeries &&
            (boatSeries.toLowerCase() == "prostar")
        ) {
            ser[i] = boatSeries;
        }
    });
    // console.log(ser);
    selectedSeries = [
        ...ser
            .filter((i) => i != null && i != "")
            .filter((val, i, arr) => arr.indexOf(val) === i),
    ];
    selectedBoxes = [...box.filter((i) => true)];
    // console.log("##198", selectedBoats, selectedSeries,selectedBoxes);
}

function boatfeature(boat) {
    const features = {};
    boat.featureList.forEach((category) => {
        const headLine = category.headline.trim().toLowerCase();
        if (!features[headLine]) {
            features[headLine] = [];
        }
        category.features.forEach((feature) => {
            const featureObj = {
                featureName: feature.featureName,
                featureDescription: feature.featureDescription,
                featureUpgrage: feature.featureUpgrade,
            };
            features[headLine].push(featureObj);
        });
    });
    // console.log(features);
    return features;
}

function comparedifference() {
    // console.log(compare.checked,typeof compare.checked);
    if (selectedBoxes.length < 2) return;
    const fw = document.querySelectorAll(".js--feature-wrapper");
    fw.forEach((item) => {
        if (selectedBoxes.length == 2) {
            let v1 = item.querySelector(
                `[data-index="${selectedBoxes[0]}"]`
            ).innerText;
            let v2 = item.querySelector(
                `[data-index="${selectedBoxes[1]}"]`
            ).innerText;
            // console.log("inside length2",item.id,item.ariaHidden,selectedBoxes,v1,v2,v1==v2);
            item.ariaHidden = v1 == v2;
        }
        if (selectedBoxes.length == 3) {
            let v0 = item.querySelector(`[data-index="0"]`).innerText;
            let v1 = item.querySelector(`[data-index="1"]`).innerText;
            let v2 = item.querySelector(`[data-index="2"]`).innerText;
            // console.log("inside length3",item.id,item.ariaHidden,selectedBoxes,v0,v1,v2,(v0==v1 && v0 == v2));
            item.ariaHidden = v0 == v1 && v0 == v2;
        }
    });
}

async function selectBoxBoatHtml(boat) {
    const {
        name,
        url: boatUrl,
        navigationImageUrl,
        price: price,
        designBoatLink,
        /*startingPrice,design */
    } = boat;
    const elem = `
  <div class="flex-col justify-start items-start gap-2 inline-flex">
  <div class="relative">
    <img class="w-11/12 object-fill h-[75px] md:h-[120px] lg:h-[134px] ${/* max-w-[8.75rem] md:max-w-[236px] lg:max-w-[250px]*/ ""}" src="${navigationImageUrl ? navigationImageUrl : ""
        }" />
    <div class="w-11/12 h-4 relative -z-10">
      <div class="h-1 w-11/12 mx-auto opacity-30 bg-neutral-800 rounded-[100%] blur-sm "></div>
    </div>
  </div>
  <div class="self-stretch flex-col justify-start items-start gap-2 flex">
    <div class="justify-start items-center gap-2 inline-flex">
      <h4 class="heading-4">${name}</h4>
      <div data-index="${currSelection}" onclick="lib.showModal(event)"
        class="w-6 h-6 p-1 bg-[rgb(224,223,220)] rounded-full justify-center items-center flex">
        <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="Edit">
            <path id="stylus"
              d="M0.803414 12.3937C0.570151 12.4493 0.367435 12.391 0.195266 12.2188C0.0230959 12.0466 -0.0352196 11.8439 0.020319 11.6106L0.686783 8.42829L3.98578 11.7273L0.803414 12.3937ZM3.98578 11.7273L0.686783 8.42829L8.31779 0.797279C8.57327 0.541801 8.88984 0.414062 9.2675 0.414062C9.64516 0.414062 9.96173 0.541801 10.2172 0.797279L11.6168 2.19685C11.8723 2.45233 12 2.7689 12 3.14656C12 3.52423 11.8723 3.8408 11.6168 4.09627L3.98578 11.7273ZM9.2675 1.73033L2.3696 8.62822L3.78584 10.0445L10.6837 3.14656L9.2675 1.73033Z"
              fill="#222222" />
          </g>
        </svg>
      </div>
    </div>
    <p class="sm">${price}</p>
    <div class="gap-2 self-stretch inline-flex flex-col items-stretch md:flex-row md:items-center md:justify-start">
      <a class="inline-flex justify-between gap-2 items-center chicklet is-link"
        href=${designBoatLink} target="_blank">Design Boat
        <svg class="size-4" width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3.99536 1.25L8.99536 6.25L3.99536 11.25" stroke="currentColor" stroke-width="1.5"
            stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </a>
      <a class="inline-flex justify-between gap-2 items-center chicklet is-link"
        href="${boatUrl}">Learn More
        <svg class="size-4" width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3.99536 1.25L8.99536 6.25L3.99536 11.25" stroke="currentColor" stroke-width="1.5"
            stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </a>
    </div>
  </div>
</div>
  `;
    return elem;
}

function removeAspectSquare() {
    boatSelectElems.forEach((item) => {
        /*console.log(item.className);*/
        item.classList.remove("aspect-square");
    });
}

// function featureHtml(features,key){
//   const idStr = key.trim().replace(/[\s~`!@#$%^&*(){}\[\];:"'<,.>?\/\\|_+=-]/g,"").toLowerCase()
//   const temp = document.createElement("div");
//   const frag = document.createDocumentFragment();
//   let feats = "";
//   features[key].forEach(item => {
//     const {featureName,featureTooltip} = item;
//     const fnId = featureName.trim().replace(/[\s~`!@#$%^&*(){}\[\];:"'<,.>?\/\\|_+=-]/g,"").toLowerCase();
//     feats+= `
//     <div class="flex p-4 md:p-6 flex-col items-center md:flex-row md:justify-between">
//       <div class="md:basis-1/4 self-start md:self-end">
//         <span class="subheading-sm">${featureName}
//           ${featureTooltip!=null ? `
//           <span onclick="lib.showToolTip(event)" class="p-1 size-6 inline-flex items-center justify-center bg-[#E0DFDC] rounded-full cursor-pointer text-base text-[#222] font-Forza leading-none" data-tooltip>&quest;</span>
//           `:""}
//         </span>
//         ${featureTooltip!=null ? `
//         <dialog data-feature-tool-tip class="w-full md:max-w-md h-full mx-auto overflow-hidden">
//           <div class=" bg-white h-full px-[var(--container-padding-sm,1.5rem)] py-[var(--stacked-component-md,4rem)] md:p-[var(--stacked-component-sm,5rem)]">
//             <span class="absolute right-4 top-4 md:right-6 md:top-6 text-[var(--text-primary)] bg-transparent hover:bg-[#E9E8E6] hover:text-gray-900 rounded-[var(--radius-md)] size-12 inline-flex justify-center items-center ">&Cross;</span>
//             <h2>${featureTooltip.featureTooltipHeader}</h2>
//             <img src="${featureTooltip.featureTooltipImageUrl}">
//             ${featureTooltip.featureTooltipDescription}
//           </div>
//         </dialog>

//         `:""}
//       </div>
//       <div id="${fnId}" class="js--compare-value flex md:basis-3/4 md:gap-[var(--container-padding-sm)] justify-between">
//         <div data-index="0" class="basis-full shrink-0"></div>
//         <div data-index="1" class="basis-full shrink-0"></div>
//         <div data-index="2" class="last:hidden md:last:block basis-full shrink-0"></div>
//       </div>
//     </div>
//     `.trim()
//   })
//   // console.log(feats.join().replace(/[\s]/g,""))
//   const elem = `
//   <h2 id="${idStr}-accordion-heading">
//       <button type="button" class="bg-black/5 justify-between w-full p-4 md:p-6 font-medium border border-transparent" data-accordion-target="#${idStr}-accordion-body" aria-expanded="true" aria-controls="${idStr}-accordion-body">
//         <h4 class="heading-4">${key}</h4>
//         <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
//           <path stroke="#111" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5" />
//         </svg>
//       </button>
//     </h2>
//     <div id="${idStr}-accordion-body" class="hidden" aria-labelledby="${idStr}-accordion-heading" >
//     ${features[key].map( item => {
//       const {featureName,featureTooltip} = item;
//       const fnId = featureName.trim().replace(/[\s~`!@#$%^&*(){}\[\];:"'<,.>?\/\\|_+=-]/g,"").toLowerCase();
//       return `
//       <div class="flex p-4 md:p-6 flex-col items-center md:flex-row md:justify-between">
//         <div class="md:basis-1/4 self-start md:self-end">
//           <span class="subheading-sm">${featureName} ${featureTooltip!=null ? `
//             <span onclick="lib.showToolTip(event)" class="p-1 size-6 inline-flex items-center justify-center bg-[#E0DFDC] rounded-full cursor-pointer text-base text-[#222] font-Forza leading-none" data-tooltip>&quest;</span>
//             `:""}
//           </span>
//           ${featureTooltip!=null ? `
//           <dialog data-feature-tool-tip class="w-full md:max-w-md h-full mx-auto overflow-hidden">
//             <div class=" bg-white h-full px-[var(--container-padding-sm,1.5rem)] py-[var(--stacked-component-md,4rem)] md:p-[var(--stacked-component-sm,5rem)]">
//               <span class="absolute right-4 top-4 md:right-6 md:top-6 text-[var(--text-primary)] bg-transparent hover:bg-[#E9E8E6] hover:text-gray-900 rounded-[var(--radius-md)] size-12 inline-flex justify-center items-center ">&Cross;</span>
//               <h2>${featureTooltip.featureTooltipHeader}</h2>
//               <img src="${featureTooltip.featureTooltipImageUrl}">
//               ${featureTooltip.featureTooltipDescription}
//             </div>
//           </dialog>
//           `:""}
//         </div>
//         <div id="${fnId}" class="js--compare-value flex md:basis-3/4 md:gap-[var(--container-padding-sm)] justify-between">
//           <div data-index="0" class="basis-full shrink-0"></div>
//           <div data-index="1" class="basis-full shrink-0"></div>
//           <div data-index="2" class="last:hidden md:last:block basis-full shrink-0"></div>
//         </div>
//       </div>
//       `
//     })}</div>
//     `
//     // const accBody = document.createElement("div");
//     // accBody.setAttribute("id",`${idStr}-accordion-body`);
//     // accBody.setAttribute("class","hidden");
//     // accBody.setAttribute("aria-labelledBy",`${idStr}-accordion-heading`);
//     // accBody.insertAdjacentHTML("beforeend",feats);
//     temp.innerHTML = elem;
//     // temp.appendChild(accBody);
//     Array.from(temp.children).forEach(child => frag.appendChild(child));
//     return frag
// }

// function featureHtml(features,key){
//   const idStr = key.trim().replace(/[\s~`!@#$%^&*(){}\[\];:"'<,.>?\/\\|_+=-]/g,"").toLowerCase()
//   const temp = document.createElement("div");
//   const frag = document.createDocumentFragment();

//   const elem = `<h2 id="${idStr}-accordion-heading">
//   <button type="button" class="bg-black/5 justify-between w-full p-4 md:p-6 font-medium border border-transparent" data-accordion-target="#${idStr}-accordion-body" aria-expanded="true" aria-controls="${idStr}-accordion-body">
//     <h4 class="heading-4">${key}</h4>
//     <svg data-accordion-icon="" class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
//       <path stroke="#111" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"></path>
//     </svg>
//   </button>
// </h2>
// <div id="${idStr}-accordion-body" class="hidden" aria-labelledby="${idStr}-accordion-heading">
// ${features[key].map(item => {
//   const {featureName,featureTooltip} = item;
//   const fnId = featureName.trim().replace(/[\s~`!@#$%^&*(){}\[\];:"'<,.>?\/\\|_+=-]/g,"").toLowerCase();
//   const txt= `<div class="flex p-4 md:p-6 flex-col items-center md:flex-row md:justify-between">
//   <div class="md:basis-1/4 self-start md:self-end">
//     <span class="subheading-sm">${featureName}</span>
//     ${featureTooltip!=null ? `
//     <span onclick="lib.showToolTip(event)" class="p-1 size-6 inline-flex items-center justify-center bg-[#E0DFDC] rounded-full cursor-pointer text-base text-[#222] font-Forza leading-none" data-tooltip="">&quest;</span>`:``}
//   </div>
//   <div id="${fnId}" class="js--compare-value flex md:basis-3/4 md:gap-[var(--container-padding-sm)] justify-between">
//     <div data-index="0" class="basis-full shrink-0"></div>
//     <div data-index="1" class="basis-full shrink-0"></div>
//     <div data-index="2" class="last:hidden md:last:block basis-full shrink-0"></div>
//   </div>
// </div>`

// return txt.trim();

// }).join('')}
// </div>
// `
// temp.innerHTML = elem;
// Array.from(temp.children).forEach(child => frag.appendChild(child));
// console.log(temp,frag);
// return frag

// }

//   const uniqueFeatures = {};
//   allBoats.forEach(boat => {
//     boat.featureList.forEach(category => {
//         if (!uniqueFeatures[category.headline]) {
//             uniqueFeatures[category.headline] = [];
//         }
//         category.features.forEach(feature => {
//             if (!uniqueFeatures[category.headline].some(f => f.featureName === feature.featureName)) {
//                 const featureObj = {
//                     featureName: feature.featureName,
//                     featureTooltip: feature.featureTooltip ? {
//                         featureTooltipHeader: feature.featureTooltipHeader,
//                         featureTooltipDescription: feature.featureTooltipDescription,
//                         featureTooltipImageUrl: feature.featureTooltipImageUrl
//                     } : null
//                 };
//                 uniqueFeatures[category.headline].push(featureObj);
//             }
//         });
//     });
//  });
// comparedifference();
// function comparedifference() {
//   const compare = document.getElementById("compare-difference");
//   compare.addEventListener("click", () => {
//     console.dir(compare.checked)
//     const fw = document.querySelectorAll('.js--feature-wrapper');
//     fw.forEach(item => {
//       const d0 = item.querySelector('[data-index="0"]');
//       const d1 = item.querySelector('[data-index="1"]');
//       const d2 = item.querySelector('[data-index="2"]');

//       console.log(item.id,{box1:d1.innerText,box2:d2.innerText,box0:d0.innerText})
//     })
//   });
